import React from "react"
import Helmet from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"

export default function PrivacyShield() {
  return (
    <Layout>
      <SEO title="Privacy Shield Privacy Policy - Compute Software"/>
      <Helmet></Helmet>
      <section className="terms-hero sub-hero jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="page-title">Privacy Shield Privacy Policy</h1>
          <p className="last-updated">Last updated: February 9, 2021</p>
        </div>
      </section>
      <section className="terms-content sub-general-content scrollable-content">
        <div className="container">
          <div className="row">
            <div className="terms col-lg-12">
                <main>
                  <div className="content">
                    <p>Compute Software, Inc. has created this Privacy Shield Privacy Policy to help you learn about how we handle Personal Information that is collected in the European Union and the United Kingdom and Switzerland (the “EEA”) and transferred to Compute Software in the U.S.</p>
                    <p>Compute Software (“Compute Software,” “we,” “us,” “our.”) complies with the EU-US Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union, the United Kingdom, and Switzerland to the United States in reliance on Privacy Shield. Compute Software has certified to the Department of Commerce that it adheres to the Privacy Shield Principles with respect to such data. If there is any conflict between the policies in this privacy policy and data subject rights under the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit&nbsp;<a href="https://www.google.com/url?q=https://www.privacyshield.gov/&amp;sa=D&amp;ust=1612130099949000&amp;usg=AOvVaw2Qu_KQPwlxHJ1NLIUbUScW">https://www.privacyshield.gov</a>.</p>
                    <p>This Privacy Shield Privacy Policy supplements our&nbsp;<a href="/privacy-policy/">Global Privacy Policy</a>. Unless specifically defined in this policy, the terms in this Privacy Shield Privacy Policy have the same meaning as in our&nbsp;<a href="/privacy-policy/">Global Privacy Policy</a>. &nbsp;In case of conflict between our&nbsp;<a href="/privacy-policy/">Global Privacy Policy</a>&nbsp;and this Privacy Shield Privacy Policy, this Privacy Shield Privacy Policy prevails. In case of conflict between this Privacy Shield Privacy Policy and the Principles, the Principles will govern.</p>
                    <h2 id="how-we-obtain-personal-information">How We Obtain Personal Information</h2>
                    <p>We obtain and process Personal Information in different capacities.</p>
                    <ul>
                      <li>As a data controller, we collect and process EEA Personal Information directly from individuals, either via our publicly available websites, including <a href="https://computesoftware.com">computesoftware.com</a>, or in connection with our customer, partner, and vendor relationships.
                      </li>
                      <li>As a data processor, we process and host EEA Personal Information obtained from our Customers (“Hosted Data”) when providing commercial hosted software applications (collectively, the “Software Services”) to our Customers. &nbsp;In that context, we only process Personal Information on behalf and instructions of our Customers, which are data controllers. &nbsp;The Software Services agreement defines the roles and responsibilities of the parties for the processing of Personal Information in the context of the Software Services (“Customer Agreement”).</li>
                    </ul>
                    <p>Compute Software commits to subject to the Principles all Personal Information received from the EEA in reliance on the Privacy Shield (which includes both types of activities).</p>
                    <h2 id="notice">Notice</h2>
                    <p>We provide information in our&nbsp;<a href="/privacy-policy/">Global Privacy Policy</a>&nbsp;regarding our privacy practices.</p>
                    <p>When we process Hosted Data, Customers determine the categories of data they upload in our systems and the purposes of the processing. For example, they may upload directly or via a service provider, various businesses and IT cost, value and utilization data at their own discretion. &nbsp;Accordingly, Customers are responsible for providing notice to individuals.</p>
                    <h2 id="data-integrity-and-purpose-limitation">Data Integrity and Purpose Limitation</h2>
                    <p>Any Personal Information we obtain may be used by Compute Software for the purposes indicated in our&nbsp;<a href="/privacy-policy/">Global Privacy Policy</a>&nbsp;or as otherwise notified to you. We will not process Personal Data in a way that is incompatible with these purposes or as subsequently authorized by you.</p>
                    <p>We take reasonable steps to limit the collection and usage of Personal Information to that which is relevant for the intended purposes for which it was collected, and to ensure that such Personal Information is reliable, accurate, complete and current.</p>
                    <p>We will retain your Personal Information in an identifiable form identifying or making identifiable individuals only for the period necessary to fulfil the purposes outlined in our&nbsp;<a href="/privacy-policy/">Global Privacy Policy</a>&nbsp;unless a longer retention period is required or permitted by law or by the Principles. We will adhere to the Principles for as long as we retain the Personal Information collected under the Privacy Shield.</p>
                    <p>When we process Hosted Data, we process and retain Personal Information only as necessary to provide our Software Services as permitted in the Customer Agreement, or as required or permitted under applicable law. &nbsp;</p>
                    <h2 id="data-disclosures">Data Disclosures</h2>
                    <p>We disclose Personal Information as described in our&nbsp;<a href="/privacy-policy/">Global Privacy Policy</a>. If we disclose it to a third party acting as a data controller or as an agent, we will comply with, and protect the Personal Information as provided in, the Accountability for Onward Transfer Principle.</p>
                    <p>When processing Hosted Data, we disclose Personal Information as provided in the Customer Agreement. Compute Software uses a limited number of third-party service providers, acting as agent, to assist us in providing our services to customers, in particular to provide data storage services.</p>
                    <p>In case of disclosure to an agent, we remain responsible for the processing of Personal Information received under the Privacy Shield and subsequently transferred to that agent if it processes such Personal Information in a manner inconsistent with the Principles, unless we prove that we are not responsible for the event giving rise to the inconsistent processing.</p>
                    <p>We may also disclose Personal Information as may be required or permitted under the Principles and under applicable law, including in response to lawful requests by public authorities, such as to meet national security or law enforcement requirements.</p>
                    <h2 id="data-security">Data Security</h2>
                    <p>We use reasonable and appropriate measures to protect your Personal Information from loss, misuse and unauthorized access, disclosure, alteration and destruction, taking into account the risks involved in the processing and the nature of the Personal Information.</p>
                    <h2 id="choice-and-access">Choice and Access</h2>
                    <p>Where appropriate, Compute Software provides you with access to the Personal Information that we maintain about you and to correct, amend or delete that information when it is inaccurate or has been processed in violation of the Principles by sending a written request as indicated in “Contact Information” below. &nbsp;We will review your request in accordance with the Principles, and may limit or deny access to Personal Information where providing such access is unreasonably burdensome or expensive under the circumstances, or as otherwise permitted by the Principles.</p>
                    <p>If we intend to use your Personal Information for a purpose that is materially different from the purposes listed in this policy or if we intend to disclose it to a third party acting as a controller not previously identified, we will offer you the opportunity to opt-out of such uses and/or disclosures where it involves non-sensitive information or opt-in where sensitive information is involved.</p>
                    <p>When we process Hosted Data, we only process and disclose the data as specified in the Customer Agreement. Customer controls the type of information we obtain, how that information is disclosed and used, and how it can be modified. &nbsp;Accordingly, if you wish to request access, to limit use or to limit disclosure, please contact the Customer who submitted your data to our services. If you provide us with the name of the Customers, we will refer your request to that Customer, and will support them as needed in responding to your request.</p>
                    <h2 id="recourse-and-enforcement">Recourse and Enforcement</h2>
                    <p>We conduct an annual self-assessment of our Personal Information practices to verify that the attestations and assertions made in this Privacy Shield Privacy Policy are true and have been implemented as represented.</p>
                    <p>In compliance with the Privacy Shield Principles, Compute Software commits to resolve complaints about your privacy and our collection or use of your personal information transferred to the United States pursuant to Privacy Shield. European Union, United Kingdom, or Swiss individuals with Privacy Shield inquiries or complaints should first contact Compute Software via email at&nbsp;<a href="mailto:privacy@computesoftware.com">privacy@computesoftware.com</a>, or via mail at: Compute Software, Attn: Privacy Officer, 1953 Sage Loop, Mountain View, CA 94043</p>
                    <p>Compute Software has further committed to refer unresolved privacy complaints under the Privacy Shield Principles to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit&nbsp;<a href="https://www.google.com/url?q=https://bbbprograms.org/privacy-shield-complaints&amp;sa=D&amp;ust=1612130099954000&amp;usg=AOvVaw1SYQb7VbjRSQ5pVO1_7Ys5">https://bbbprograms.org/privacy-shield-complaints/</a>&nbsp;for more information and to file a complaint. This service is provided free of charge to you.</p>
                    <p>If your Privacy Shield complaint cannot be resolved through the above channels, under certain conditions, you may invoke binding arbitration for some residual claims not resolved by other redress mechanisms. See Privacy Shield Annex 1 at&nbsp;<a href="https://www.google.com/url?q=https://www.privacyshield.gov/article?id%3DANNEX-I-introduction&amp;sa=D&amp;ust=1612130099955000&amp;usg=AOvVaw0i2S8AFzaZqXMwYnnExZkL">https://www.privacyshield.gov/article?id=ANNEX-I-introduction</a></p>
                    <p>Our commitments under the Privacy Shield are subject to the investigatory and enforcement powers of the United States Federal Trade Commission.</p>
                    <h2 id="changes-to-the-privacy-shield-privacy-policy">Changes to the Privacy Shield Privacy Policy</h2>
                    <p>This Privacy Shield Privacy Policy may be changed from time to time, consistent with the requirements of the Privacy Shield. You can determine when this Privacy Shield Privacy Policy was last revised by referring to the “Last Updated” legend at the top of this page. Any changes to this Privacy Shield Privacy Policy will become effective when we post the revised version on our website</p>
                    <p>Contact Information</p>
                    <p>If you have any questions, concerns or complaints regarding our privacy practices, or if you’d like to exercise your choices or rights, contact us via email at&nbsp;<a href="mailto:privacy@computesoftware.com">privacy@computesoftware.com</a>.</p>
                  </div>
                  <nav className="table-of-contents">
                    <div className="inner-wrapper-sticky">
                      <h3>Table of Contents</h3>
                      <ol>
                        <li><a className="nav-link" href="#how-we-obtain-personal-information">How We Obtain Personal Information</a></li>
                        <li><a className="nav-link" href="#notice">Notice</a></li>
                        <li><a className="nav-link" href="#data-integrity-and-purpose-limitation">Data Integrity and Purpose Limitation</a></li>
                        <li><a className="nav-link" href="#data-disclosures">Data Disclosures</a></li>
                        <li><a className="nav-link" href="#data-security">Data Security</a></li>
                        <li><a className="nav-link" href="#choice-and-access">Choice and Access</a></li>
                        <li><a className="nav-link" href="#recourse-and-enforcement">Recourse and Enforcement</a></li>
                        <li><a className="nav-link" href="#changes-to-the-privacy-shield-privacy-policy">Changes to the Privacy Shield Privacy Policy</a></li>
                      </ol>
                    </div>
                  </nav>
                </main>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  )
}
